import { EmailSent } from 'components/icons/emailSent'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import React from 'react'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { EmailPreference, PREFERENCE_UPDATE_FAILURE, PREFERENCE_UPDATE_SUCCESS } from './constants'
import * as Flash from 'components/flash'
import './_styles.scss'
import { BillingFeature } from './billingFeature'
import { BillingPlanDetail } from '../../../shared_server_client/types/billing_plan'

type SettingsAccountFeatureProps = GenericRedux.AllComponentProps<EmailPreference>

interface SettingsAccountFeatureState {
  communityId: number,
  userCommunityId: number
  billingDetails?: BillingPlanDetail[]
  productSubscriptionId?: number
}
const emptyState: SettingsAccountFeatureState = {
  communityId: null,
  userCommunityId: null,
}

class AccountFeatureComponent extends RasaReactComponent<
  SettingsAccountFeatureProps, SettingsAccountFeatureState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  constructor(props: SettingsAccountFeatureProps) {
    super(props, 'productSubscriptionDetail', emptyState)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
      .then((activeCommunity) => {
        this.setState({
          communityId: activeCommunity.communityId,
          userCommunityId: activeCommunity.data.community_user_id,
          billingDetails: activeCommunity.billingInfo.currentPlan.details || [],
          productSubscriptionId: activeCommunity.productSubscriptionId
        })
      })
  }

  public render() {
    return (
      <div className="settings-email-preference-wrapper">
        <div>
          <div className="contentpane-header-wrapper">
            <div className="contentpane-icon-wrapper">
              <EmailSent/>
            </div>
            <div className="header">
              <div className="header-outter-wrapper">
                <div className="contentpane-section-title-wrapper">
                  <h6 className="section-header-title">Account Features</h6>
                  <div className="section-header-text">Account Features</div>
                </div>
              </div>
            </div>
          </div>
          <div className="email-preferences">
            {this.state.billingDetails &&
              this.state.billingDetails.map((plan: BillingPlanDetail) =>
              <BillingFeature key={plan.name} plan={plan}
                                   onSave={this.onSave}
                                   onToggle={this.onToggle} />)}
          </div>
        </div>
      </div>
    )
  }

  private onSave = (plan: BillingPlanDetail) => {
    this.update(plan)
  }
  private update = (plan: BillingPlanDetail) => {
    this.context.entityMetadata.getEntityObject(this.entityName)
      .then((entity) => {
        entity.setRecordId(this.state.communityId,null)
        entity.data.product_subscription_id = this.state.productSubscriptionId
        entity.data.description = plan.billing_plan_detail_description
        entity.data.billing_plan_code = plan.billing_plan_code
        entity.data.minimum = 0
        entity.data.maximum = plan.productDetailMaximum
        entity.data.limit_val = plan.limit_val
        entity.data.is_active = plan.productDetailActive
        return entity.save().then(() => {
          this.context.store.dispatch(Flash.showFlashMessage(PREFERENCE_UPDATE_SUCCESS))
        })
          .catch(() => {
            this.context.store.dispatch(Flash.showFlashError(PREFERENCE_UPDATE_FAILURE))
          })
      })
  }
  private onToggle = (plan: BillingPlanDetail) => {
    this.update(plan)
  }
}

export const AccountFeature = GenericRedux.registerNewNestedComponent(
  AccountFeatureComponent, 'settings_email_preferences')
