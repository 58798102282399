import React from 'react'
import './styles.css'

interface CustomTemplateUnavailableProps {
  displayMessage?: string,
}

export const CustomTemplateUnavailableComponent = (props: CustomTemplateUnavailableProps) => {
  return <div className="custom-template-unavailable">
    {props.displayMessage ? props.displayMessage : 'Upload your own HTML template design' }
  </div>
}
