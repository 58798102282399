import { State } from "./types"

export const REDUX_STORE_HOME = 'article_activity'

export const InitialState: State = {
  data: {
    article_fetch_error: '',
    message: '',
    success: false,
    node_check_results: [],
    python_check_results: [],
  },
  error: '',
  isLoading: true,
  selectedCommunity: '',
  stillNoLuck: false,
  url: '',
  headers: '',
}
