import React from 'react'

import { ToggleOff } from 'components/icons/toggleoff'
import { ToggleOn } from 'components/icons/toggleon'
import { BillingPlanDetail } from '../../../shared_server_client/types/billing_plan'
import { BillingCodeType } from './constants'
interface BillingFeatureProps extends React.HTMLAttributes<HTMLElement> {
  plan: BillingPlanDetail,
  onToggle: any
  onSave: any
}
interface BillingFeatureState {
  limitVal: number
  productDetailActive?: number
  isDirty?: boolean
}

export class BillingFeature extends React.Component<BillingFeatureProps,BillingFeatureState> {
  constructor(props: BillingFeatureProps) {
    super(props)
    this.state = {
      limitVal: 0,
      productDetailActive: 0,
      isDirty: false,
    }
  }

  public componentDidMount = () => {
    this.setState({
      limitVal : this.props.plan? this.props.plan.limit_val : 0,
      productDetailActive: this.props.plan.product_subscription_detail_active
    })
  }
  public render() {
    return (
      <div className={this.props.className ? 'preference-box ' + this.props.className : 'preference-box'}>
        <div className="box-body">
          <div className="left">
            <div className="box-header">{this.props.plan.name}</div>
            <div className="box-content">{this.props.plan.billing_plan_detail_description}</div>
          </div>
          <div className="right">
            {this.props.plan.billing_plan_code_type === BillingCodeType.BOOLEAN &&
            <div onClick={() => {
              const updatedValue = this.state.productDetailActive ? 0 : 1
              this.setState(prevState => ({...prevState, productDetailActive: updatedValue}))
              this.props.onToggle({...this.props.plan, productDetailActive: updatedValue})
            }} className="toggle-active">
              <button className="toggle-button" style={{background: 'none', border: 'none', outline: 'none'}}>
                {this.state.productDetailActive ? <ToggleOn/> : <ToggleOff/>}
              </button>
            </div> }
            {this.props.plan.billing_plan_code_type === BillingCodeType.TEXT && (
              <div className="setting-input">
                <input type="number" className="form-control" value={this.state.limitVal}
               onChange={this.handleInputChange} />
                {this.state.isDirty &&   <div className="setting-button">
                  <button className="btn btn-secondary" onClick={this.handleSave}>Save</button>
                  <button className="btn btn-secondary" onClick={this.handleCancel}>Cancel</button>
                </div>}
              </div>
            )}
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    )
  }

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value
    this.setState({
      limitVal: Number(newValue),
      isDirty: Number(newValue) !== this.props.plan.limit_val
    })
  }
  private handleSave = () => {
    this.props.onSave({
      ...this.props.plan,
      limit_val: this.state.limitVal
    })
    this.setState({ isDirty: false })
  }
  private handleCancel = () => {
    this.setState({
      limitVal: this.props.plan.limit_val || 0,
      isDirty: false
    })
  }
}
