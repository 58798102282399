import { State } from "./types"

export const REDUX_STORE_HOME = 'article_activity'
export const SOURCE_LIMIT = 10
export const InitialState: State = {
  data: [],
  error: '',
  isLoading: true,
  selectedCommunity: '',
  url: '',
}
export const FILE_NOT_FOUND_ERROR = 'File not found.'
